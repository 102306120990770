
import { defineComponent, provide } from 'vue';
import * as echarts from 'echarts';

export default defineComponent({
  name: 'App',
  setup() {
    provide('ec', echarts)
  },
})
