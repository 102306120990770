import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus';
import {Expand, ChatRound} from '@element-plus/icons'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css';
import axios from 'axios';


const app = createApp(App);

//全局挂载axios
app.config.globalProperties.$axios = axios;

app.use(store);
app.use(router);
app.use(ElementPlus);
app.component('expand',Expand);
app.component('chat-round',ChatRound);
app.mount('#app');
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }


