import {createStore} from 'vuex';
import {storage} from '@/utils/storage'

interface States{
    //用户登录token
    token : string,
    //用户信息
    user : Object
};

const userInfo = createStore<States>({
    state() {
        return {
            token : (storage.get('token') === undefined || storage.get('token') === '') ? '' : storage.get('token') as string,
            user : {}
        }
    },
    mutations : {
        //存入token
        saveToken(state:any, token:string){
            //重置vuex中的token
            state.token = token
            //重置storage中的token
            storage.set('token', token)
        },
        //获取token数据
        getToken(state:any){
            if (state.token !== '') {
                return JSON.parse(JSON.stringify(state.token)).token
            }
            return ''
        },
        //清空token
        delToken(state:any){
            //清除vuex中的token
            state.token = ''
            //清除storage中的token
            storage.remove('token')
        },
        //存储当前登录人信息
        saveUser(state:any, user:Object){
            state.user = user
        },
        delUser(state:any){
            state.user = null
        }
    }
});

export default userInfo;