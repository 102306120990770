import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import userInfo from '@/store/modules/userInfo'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/Index.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>import('../views/login/index.vue')
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: () =>import('../views/error/404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// 导航守卫
// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
router.beforeEach((to, from, next) => {
  if (to.path === "/login") {
    next();
  } else {
    //获取vuex中的token
    let token = userInfo.state.token;
    if (token === undefined || token === "") {
      //如果用户没有登录强制跳转到登录页面
      next("/login");
    } else {
      //放行
      next();
    }
  }
});


export default router
